import React from "react"


const DateTime = props => (
  <p>
    <time dateTime={props.datetime}>{props.dateformatted}</time>
  </p>
)

export default DateTime
