import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Link } from "gatsby"
import Img from "gatsby-image"
import DateTime from "../components/datetime"

const ProjectTiles = () => {
  const data = useStaticQuery(graphql`
    query ProjectList {
      allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }, filter: {fileAbsolutePath: {regex: "/projects/"}}) {
        edges {
          node {
            id
            frontmatter {
              title
              htmldate: date
              year: date(formatString: "YYYY")
              date(formatString: "MMMM YYYY")
              featuredImg {
                childImageSharp {
                  fluid(maxWidth: 1600, maxHeight: 900, cropFocus: CENTER) {
                    ...GatsbyImageSharpFluid_tracedSVG
                  }
                }
              }
            }
            fields {
              slug
            }
          }
        }
      }
    }
  `)

  return (
    <>
      <ol className="{styles.container} flex flex-wrap overflow-visible justify-between m-0 p-0 list-none">
        {data.allMarkdownRemark.edges.map(({ node }, index) => (
          <li key={node.id} className={`mb-8 w-full ${index === 0 ? `` : `sm:w-[48%]`} block`}>
            <Link to={node.fields.slug} className="block">
              <article className="{styles.article} border-solid dark:ring-1 dark:ring-gray-800 overflow-hidden flex flex-col min-h-full rounded-md shadow-lg duration-200 ease-in-out hover:shadow-2xl dark:bg-gray-900">
                <Img fluid={node.frontmatter.featuredImg.childImageSharp.fluid} />
                <h2 className="{styles.title} text-xl block px-4 pt-4 pb-0 m-0 flex-grow font-bold">{node.frontmatter.title}</h2>
                <div className="{styles.subhead} block pb-4 px-4 flex-grow"><DateTime datetime={node.frontmatter.htmldate} dateformatted={node.frontmatter.date} /></div>
              </article>
            </Link>
          </li>
        ))}
      </ol>
    </>
  )
}

export default ProjectTiles
