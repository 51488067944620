import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import ProjectTiles from "../components/project_tiles"

const ProjectsPage = () => (
  <Layout>
    <SEO title="Projects"
    description="A list of the web development projects that I have worked on in the past."/>
    <h1>Projects</h1>
    <p>Listed here are a few web development projects that I've worked on in the past.
    I've also worked on more recent projects, but I can't list them here by name. These include:</p>
    <ul>
      <li>Maintenance of Drupal 7 and 8 codebases, configuring modules and managing site configuration.</li>
      <li>Putting design systems into practice in template based implementations.</li>
      <li>Setting up caching and search index integrations—Memcached, Varnish, Solr.</li>
      <li>Upgrading legacy projects and environments from PHP 5 to PHP 7.</li>
      <li>Preparing and configuring Continuous Integration (CI) to automate testing and code builds.</li>
    </ul>
    <ProjectTiles />
  </Layout>
)

export default ProjectsPage
